import React from 'react';
import BlockSettings from './BlockSettings';
import { Calendars, StyledQuickEditOverlay, Title } from './styles';
import { IEventCalendar } from './utils';
import usePageBlock from 'hooks/use-page-block';
import MiniCalendar from './MiniCalendar';
import { EventCalendarProvider } from './EventCalendarContext';
import WeekCalendar from './WeekCalendar';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { ICalendarEvent } from 'models/ICalendarEvent';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';

export interface IEventCalendarItem {
  arrayId: string;
  data: IEventCalendar;
  kind: string;
}

type IEventCalendarProps = IPageBlockProps<IEventCalendarItem>;

type EditableStringPath = 'title.rawData';

const EventCalendar: React.FC<IEventCalendarProps> = ({
  item,
  onEdit,
  onRemove,
  onMoveDown,
  onMoveUp,
}) => {
  const isMobile = useSelector(isMobileLayout);
  const [mobileSelectedEvent, setMobileSelectedEvent] = React.useState<ICalendarEvent | null>(null);

  const handleChangeData = React.useCallback((update: IEventCalendar) => {
    onEdit({ ...item, data: update });
  }, [item, onEdit]);

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<EditableStringPath, IEventCalendar>({
    item: item.data,
    onChange: handleChangeData,
  });

  const renderTitle = React.useCallback(() => {
    if (!item.data.title.show) {
      return null;
    }

    return (
      <Title
        blockTitleKey="ADMIN_LABEL_TITLE"
        onChange={handleChange('title.rawData')}
        data={item.data.title.rawDataV2}
        useRawDataV2={true}
        alignment={item.data.textAlignment}
        data-testid="textTitle"
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
      />
    );
  }, [
    item.data.title,
    handleChange,
    item.data.textAlignment,
  ]);

  return (
    <EventCalendarProvider>
      <StyledQuickEditOverlay
        backgroundColor={item.data.background.color}
        onRemove={onRemove}
        titleKey="LABEL_EVENT_CALENDAR"
        onEdit={handleOpenSettings}
        testId={`eventCalendar-${item.arrayId}`}
        hasMobileSelectedEvent={!!mobileSelectedEvent}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
      >
        {renderTitle()}
        <Calendars>
          {!isMobile && <WeekCalendar />}
          <MiniCalendar
            mobileSelectedEvent={mobileSelectedEvent}
            setMobileSelectedEvent={setMobileSelectedEvent}
          />
        </Calendars>
        <BlockSettings
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
          item={item.data}
          onChange={handleChangeData}
        />
      </StyledQuickEditOverlay>
    </EventCalendarProvider>
  );
};

export default EventCalendar;
